"use client";

import React, { useRef, useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import Loader from '../loader/loader';
import { getRemoteConfig } from '../../api/shared';
import './other.css';

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface BarChartComponentProps {
    groupData: any;
    selectedGroup: string;
    startDate: Date | null;
    endDate: Date | null;
    loading: boolean;
}

// this is grabbing the remote config data for the baseline multipliers
const getRemoteConfigData = async () => {
    try {
        const response = await getRemoteConfig('baselineMultipliers');

        let data;
        if (typeof response === 'string') {
            data = JSON.parse(response);
        } else {
            data = response;
        }

        const servicesArray = data.services;

        if (!Array.isArray(servicesArray)) {
            console.error('Services array is missing or invalid');
            return null;
        }

        return servicesArray;
    } catch (error) {
        console.error('Error fetching remote config data:', error);
        return null;
    }
};


const BarChartComponent: React.FC<BarChartComponentProps> = ({ groupData, selectedGroup, startDate, endDate, loading }) => {
    const [chartData, setChartData] = useState<any>(null);
    const [baselines, setBaselines] = useState<any>(null);
    const chartRef = useRef<any>(null);

    // Baseline rates are in GB/hr
    // const baselines720: { [key: string]: number } = {
    //     'Netflix': 1.5,
    //     'YouTube': 1.3,
    //     'Amazon': 1.2,
    //     'Viki': 1.3,
    //     'Default': 1.2,
    // };

    // const baselines480: { [key: string]: number } = {
    //     'Netflix': .3,
    //     'YouTube': .24,
    //     'Amazon': .6,
    //     'Viki': .4,
    //     'Default': .4,
    // };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const servicesArray = await getRemoteConfigData();
    
                // Check if servicesArray is a valid array
                if (!Array.isArray(servicesArray)) {
                    console.error('Services array is missing or invalid');
                    return;
                }
    
                // Normalize the service names
                const nameNormalization: { [key: string]: string } = {
                    youtube: 'YouTube',
                    netflix: 'Netflix',
                    primeVideo: 'Prime Video',
                    paramountPlus: 'Paramount+',
                    foxNation: 'Fox Nation',
                    peacock: 'Peacock',
                    hulu: 'Hulu',
                    max: 'Max',
                };
    
                const baselinesObj = servicesArray.reduce((acc, service) => {
                    // Access the key dynamically for each service (e.g., youtube, netflix)
                    const key = Object.keys(service)[0];
    
                    // Access the service details
                    const { name, baselineMultiplier } = service[key];
    
                    // Normalize the name to match the keywordMapping keys
                    const normalizedName = nameNormalization[key] || name;
    
                    acc[normalizedName] = baselineMultiplier; // Map normalized service name to baseline multiplier
                    return acc;
                }, {});
    
                // Log the final baselines object to verify correctness
                console.log('Final baselines object:', baselinesObj);
    
                setBaselines(baselinesObj);
            } catch (error) {
                console.error('Error processing baseline rates:', error);
            }
        };
        fetchData();
    }, []);
    
    
    
    
    useEffect(() => {
        if (groupData && selectedGroup && startDate && endDate && baselines) {
            const filteredData = groupData.filter((item: any) => {
                const itemDate = new Date(item.startTime);
                return itemDate >= startDate && itemDate <= endDate;
            });
            const processedData = processChartData(filteredData, baselines);
            setChartData(processedData);
        }
    }, [groupData, selectedGroup, startDate, endDate]);

    useEffect(() => {
        const resizeChart = () => {
            if (chartRef.current && chartRef.current.chartInstance) {
                chartRef.current.chartInstance.resize();
            }
        };

        window.addEventListener('resize', resizeChart);

        return () => {
            window.removeEventListener('resize', resizeChart);
        };
    }, []);

    const processChartData = (data: any, baselines: any) => {
        const keywordMapping: { [key: string]: string } = {
            // This is mapping the nameConf to the keyword to group the data
            'aiv-delivery.net': 'Prime Video',
            'amazonvideo.com': 'Prime Video',
            'Prime Video': 'Prime Video',
            'amazon': 'Prime Video',
            'nflxvideo.net': 'Netflix',
            'netflix.com': 'Netflix',
            'netflix': 'Netflix',
            'Netflix': 'Netflix',
            'logs.netflix.com': 'Netflix',
            'android.prod.ftl.netflix.com': 'Netflix',
            'android-appboot.netflix.com': 'Netflix',
            'tiktokv.us': 'Tiktok',
            'TikTok': 'TikTok',
            'viki.io': 'Viki',
            'googlevideo': 'YouTube',
            'youtube.com': 'YouTube',
            'youtube': 'YouTube',
            'Youtube': 'YouTube',
            'YouTube': 'YouTube',
            'Paramount+': 'Paramount+',
            'Fox Nation': 'Fox Nation',
            'Peacock': 'Peacock',
            'Hulu': 'Hulu',
            'MAX': 'Max',
            'Max': 'Max',
        };

        const cssVars = getComputedStyle(document.documentElement);
        const colorMapping: { [key: string]: { background: string, border: string } } = {
            'YouTube': {
                background: cssVars.getPropertyValue('--other-color'),
                border: cssVars.getPropertyValue('--other-boarder'),
            },
            'Netflix': {
                background: cssVars.getPropertyValue('--other-color'),
                border: cssVars.getPropertyValue('--other-boarder'),
            },
            'Prime Video': {
                background: cssVars.getPropertyValue('--other-color'),
                border: cssVars.getPropertyValue('--other-boarder'),
            },
            'Viki': {
                background: cssVars.getPropertyValue('--other-color'),
                border: cssVars.getPropertyValue('--other-boarder'),
            },
            'TikTok': {
                background: cssVars.getPropertyValue('--other-color'),
                border: cssVars.getPropertyValue('--other-boarder'),
            },
            'Paramount+': {
                background: cssVars.getPropertyValue('--other-color'),
                border: cssVars.getPropertyValue('--other-boarder'),
            },
            'Fox Nation': {
                background: cssVars.getPropertyValue('--other-color'),
                border: cssVars.getPropertyValue('--other-boarder'),
            },
            'Peacock': {
                background: cssVars.getPropertyValue('--other-color'),
                border: cssVars.getPropertyValue('--other-boarder'),
            },
            'Hulu': {
                background: cssVars.getPropertyValue('--other-color'),
                border: cssVars.getPropertyValue('--other-boarder'),
            },
            'Max': {
                background: cssVars.getPropertyValue('--other-color'),
                border: cssVars.getPropertyValue('--other-boarder'),
            },
            'Baseline': {
                background: cssVars.getPropertyValue('--baseline-black'),
                border: cssVars.getPropertyValue('--baseline-border'),
            },
            'Other': {
                background: cssVars.getPropertyValue('--other-color'),
                border: cssVars.getPropertyValue('--other-boarder'),
            },
        };

        const aggregatedData: { [key: string]: { dataUsage: number, duration: number } } = {};
        const baselineData: { [key: string]: number } = {};

        data.forEach((item: any) => {
            const nameConf = item.nameConf;
            const dataUsage = item.dataUsage;
            const duration = item.duration;

            let domainGroup = 'Other';
            for (const keyword in keywordMapping) {
                if (nameConf.includes(keyword)) {
                    domainGroup = keywordMapping[keyword];
                    break;
                }
            }
            if (aggregatedData[domainGroup]) {
                aggregatedData[domainGroup].dataUsage += dataUsage;
                aggregatedData[domainGroup].duration += duration;
            } else {
                aggregatedData[domainGroup] = { dataUsage: dataUsage, duration };
            }

            if (baselineData[domainGroup]) {
                baselineData[domainGroup] += duration;
            } else {
                baselineData[domainGroup] = duration;
            }
        });

        const labels = Object.keys(aggregatedData);
        const dataUsage = Object.values(aggregatedData).map(item => item.dataUsage);
        const durations = Object.values(aggregatedData).map(item => item.duration);
        const backgroundColors = labels.map(label => colorMapping[label]?.background || 'rgba(0, 0, 0, 0.2)');
        const borderColors = labels.map(label => colorMapping[label]?.border || 'rgba(0, 0, 0, 1)');

        return {
            labels,
            datasets: [
                {
                    label: 'Data Usage',
                    data: dataUsage,
                    backgroundColor: backgroundColors,
                    borderColor: borderColors,
                    borderWidth: 1,
                    borderRadius: 10,
                },
                {
                    label: "Projected Baseline",
                    data: labels.map((label, index) => {
                        const duration = durations[index]; // Duration in seconds for this service
                        const baselineMultiplierRaw = baselines[label] || baselines['Default'];
                        if (!baselineMultiplierRaw || !duration) {
                            return 0;
                        }

                        // Convert baselineMultiplier to rate per second
                        // Assuming baselineMultiplier is in MB/hr, convert to MB/s
                        const baselineRateMBPerSec = (baselineMultiplierRaw) / 3600; // MB/hr to MB/s

                        // Calculate projected data usage in MB
                        const projectedUsageMB = baselineRateMBPerSec * duration;

                        // Convert MB to bytes
                        const projectedUsageBytes = projectedUsageMB * 1_000_000;

                        // XXX: Comment out until we fix/get actual duration baseline calc
                        //
                        //const baselineRate = baselines480[group] || baselines480['Default'];
                        //return baselineRate * (baselineData[group] / 3600) * 1000 * 1000 * 1000;

                        return projectedUsageBytes;
                    }),
                    backgroundColor: colorMapping['Baseline'].background,
                    borderColor: colorMapping['Baseline'].border,
                    borderWidth: 1,
                    borderRadius: 10,
                }
            ],
            durations,
        };
    };

    const formatBytes = (bytes: number, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1000;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    const formatDuration = (duration: number) => {
        const days = Math.floor(duration / (60 * 60 * 24));
        const hours = Math.floor(duration / (60 * 60)) % 24;
        const minutes = Math.floor(duration / 60) % 60;
        const seconds = Math.floor(duration) % 60;
        return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    };

    if (loading) {
        return <div className="centered-container"><Loader /></div>;
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 1000 * 1000 * 1000,
                    callback: function(value: any) {
                        return formatBytes(value);
                    }
                }
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        const label = context.dataset.label || '';
                        const value = context.raw;
                        const duration = chartData.durations[context.dataIndex];
                        return [
                            `${label}: ${formatBytes(value)}`,
                            `Duration: ${formatDuration(duration)}`,
                        ];
                    }
                }
            },
            datalabels: {
                display: false,
            },
        }
    };

    return (
        <div className='chart-container grid-item' style={{ width: '100%', height: '100%'}}>
            {chartData ? (
                <Bar ref={chartRef} data={chartData} options={options} />
            ) : (
                <div className='how-to'>Select a group and a date range to view your graphs.</div>
            )}
        </div>
    );
};

export default BarChartComponent;
