import React, { useEffect, useState } from "react";
import {getRootGroups, getGroupInfo, updateGroup, createGroup} from "../../api/shared";
import { RootGroup, shapingData, shapingValue } from "../../models/models";
import { errorHandler } from "../errorMessages/errorHandler"
import { toaster } from "evergreen-ui"
import { Pane, Table, Button, majorScale, SideSheet, Heading, Paragraph, TextInput, Switch, Text, Combobox, Pagination } from "evergreen-ui";
import "./styles.css";
import {defaultShapingLevel, LowShapingLevel, NoneShapingLevel, ShapingLevel, shapingLevels, toShapingLevel} from "../../models/ShapingLevel";
import AdvSettings from "./advanced";
import LoadingWord from "../loader/loadingWord";

//This is the default group that will be created when the create button is clicked
const newGroup: RootGroup = {
  name: '',
  bypassWiFiTraffic: true,
  levelOfSavings: defaultShapingLevel.name,
  shapingData: {
    YouTube: { target: defaultShapingLevel.name },
    Netflix: { target: defaultShapingLevel.name },
    Amazon: { target: defaultShapingLevel.name },
    Viki: { target: defaultShapingLevel.name },
  },
};

const itemsPerPage = 10;

const GroupRootList: React.FC = () => {
  //These const are used to store the values of the group settings
  const [loading, setLoading] = useState<boolean>(true);
  const [rootData, setRootData] = useState<RootGroup[]>([]);
  const [isSideSheetOpen, setIsSideSheetOpen] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<RootGroup | null>(null);
  const [newGroupName, setNewGroupName] = useState<string>('');
  const [bypassWiFiTraffic, setBypassWiFiTraffic] = useState<boolean>(false);
  const [levelOfYT, setLevelOfYT] = useState<ShapingLevel>(defaultShapingLevel);
  const [levelOfNetflix, setLevelOfNetflix] = useState<ShapingLevel>(defaultShapingLevel);
  const [levelOfAmazon, setLevelOfAmazon] = useState<ShapingLevel>(defaultShapingLevel);
  const [levelOfHulu, setLevelOfHulu] = useState<ShapingLevel>(defaultShapingLevel);
  const [levelOfPeacock, setLevelOfPeacock] = useState<ShapingLevel>(defaultShapingLevel);
  const [levelOfFoxNation, setLevelOfFoxNation] = useState<ShapingLevel>(defaultShapingLevel);
  const [levelOfParamount, setLevelOfParamount] = useState<ShapingLevel>(defaultShapingLevel);
  const [levelOfMax, setLevelOfMax] = useState<ShapingLevel>(defaultShapingLevel);
  const [levelOfViki, setLevelOfViki] = useState<ShapingLevel>(defaultShapingLevel);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>('');

  //This function is used to reset the shaping data when the user saves all changes to a group
  //This prevents the data carrying over to the next group
  const resetShapingData = () => {
    setLowYT({target: 800000, targetDataRate: 300});
    setMedYT({target: 640000, targetDataRate: 240});
    setHighYT({target: 512000, targetDataRate: 192});
    setNoneYT({target: 0, targetDataRate: 0});

    setLowNtfx({target: 800000, targetDataRate: 300});
    setMedNtfx({target: 640000, targetDataRate: 240});
    setHighNtfx({target: 512000, targetDataRate: 192});
    setNoneNtfx({target: 0, targetDataRate: 0});

    setLowAmzn({target: 800000, targetDataRate: 300});
    setMedAmzn({target: 640000, targetDataRate: 240});
    setHighAmzn({target: 512000, targetDataRate: 192});
    setNoneAmzn({target: 0, targetDataRate: 0});

    setLowHulu({target: 800000, targetDataRate: 300});
    setMedHulu({target: 640000, targetDataRate: 240});
    setHighHulu({target: 512000, targetDataRate: 192});
    setNoneHulu({target: 0, targetDataRate: 0});

    setLowPck({target: 800000, targetDataRate: 300});
    setMedPck({target: 640000, targetDataRate: 240});
    setHighPck({target: 512000, targetDataRate: 192});
    setNonePck({target: 0, targetDataRate: 0});

    setLowFox({target: 800000, targetDataRate: 300});
    setMedFox({target: 640000, targetDataRate: 240});
    setHighFox({target: 512000, targetDataRate: 192});
    setNoneFox({target: 0, targetDataRate: 0});

    setLowPmnt({target: 800000, targetDataRate: 300});
    setMedPmnt({target: 640000, targetDataRate: 240});
    setHighPmnt({target: 512000, targetDataRate: 192});
    setNonePmnt({target: 0, targetDataRate: 0});

    setLowMax({target: 800000, targetDataRate: 300});
    setMedMax({target: 640000, targetDataRate: 240});
    setHighMax({target: 512000, targetDataRate: 192});
    setNoneMax({target: 0, targetDataRate: 0});

    setLowViki({target: 800000, targetDataRate: 300});
    setMedViki({target: 640000, targetDataRate: 240});
    setHighViki({target: 512000, targetDataRate: 192});
    setNoneViki({target: 0, targetDataRate: 0});
  };

  // THIS IS ALL THE SHAPING VALUES!! DO NOT TOUCH! IF YOU BREAK MY CODE I WILL BREAK YOU
  //YouTube
  const [lowYT, setLowYT] = useState<shapingValue>({target: 800000, targetDataRate: 300});
  const [medYT, setMedYT] = useState<shapingValue>({target: 640000, targetDataRate: 240});
  const [highYT, setHighYT] = useState<shapingValue>({target: 512000, targetDataRate: 192});
  const [noneYT, setNoneYT] = useState<shapingValue>({target: 0, targetDataRate: 0});

  //Netflix
  const [lowNtfx, setLowNtfx] = useState<shapingValue>({target: 950000, targetDataRate: 300});
  const [medNtfx, setMedNtfx] = useState<shapingValue>({target: 640000, targetDataRate: 240});
  const [highNtfx, setHighNtfx] = useState<shapingValue>({target: 512000, targetDataRate: 192});
  const [noneNtfx, setNoneNtfx] = useState<shapingValue>({target: 0, targetDataRate: 0});

  //Amazon
  const [lowAmzn, setLowAmzn] = useState<shapingValue>({target: 800000, targetDataRate: 300});
  const [medAmzn, setMedAmzn] = useState<shapingValue>({target: 640000, targetDataRate: 240});
  const [highAmzn, setHighAmzn] = useState<shapingValue>({target: 512000, targetDataRate: 192});
  const [noneAmzn, setNoneAmzn] = useState<shapingValue>({target: 0, targetDataRate: 0});

  //Hulu
  const [lowHulu, setLowHulu] = useState<shapingValue>({target: 800000, targetDataRate: 300});
  const [medHulu, setMedHulu] = useState<shapingValue>({target: 640000, targetDataRate: 240});
  const [highHulu, setHighHulu] = useState<shapingValue>({target: 512000, targetDataRate: 192});
  const [noneHulu, setNoneHulu] = useState<shapingValue>({target: 0, targetDataRate: 0});

  //Peacock
  const [lowPck, setLowPck] = useState<shapingValue>({target: 800000, targetDataRate: 300});
  const [medPck, setMedPck] = useState<shapingValue>({target: 640000, targetDataRate: 240});
  const [highPck, setHighPck] = useState<shapingValue>({target: 512000, targetDataRate: 192});
  const [nonePck, setNonePck] = useState<shapingValue>({target: 0, targetDataRate: 0});

  //Fox Nation
  const [lowFox, setLowFox] = useState<shapingValue>({target: 800000, targetDataRate: 300});
  const [medFox, setMedFox] = useState<shapingValue>({target: 640000, targetDataRate: 240});
  const [highFox, setHighFox] = useState<shapingValue>({target: 512000, targetDataRate: 192});
  const [noneFox, setNoneFox] = useState<shapingValue>({target: 0, targetDataRate: 0});

  //Paramount plus
  const [lowPmnt, setLowPmnt] = useState<shapingValue>({target: 800000, targetDataRate: 300});
  const [medPmnt, setMedPmnt] = useState<shapingValue>({target: 640000, targetDataRate: 240});
  const [highPmnt, setHighPmnt] = useState<shapingValue>({target: 512000, targetDataRate: 192});
  const [nonePmnt, setNonePmnt] = useState<shapingValue>({target: 0, targetDataRate: 0});

  //Max
  const [lowMax, setLowMax] = useState<shapingValue>({target: 800000, targetDataRate: 300});
  const [medMax, setMedMax] = useState<shapingValue>({target: 640000, targetDataRate: 240});
  const [highMax, setHighMax] = useState<shapingValue>({target: 512000, targetDataRate: 192});
  const [noneMax, setNoneMax] = useState<shapingValue>({target: 0, targetDataRate: 0});

  //Viki
  const [lowViki, setLowViki] = useState<shapingValue>({target: 800000, targetDataRate: 300});
  const [medViki, setMedViki] = useState<shapingValue>({target: 640000, targetDataRate: 240});
  const [highViki, setHighViki] = useState<shapingValue>({target: 512000, targetDataRate: 192});
  const [noneViki, setNoneViki] = useState<shapingValue>({target: 0, targetDataRate: 0});

  //This function is called when the table is called
  useEffect(() => {
    fetchData();
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        setIsSideSheetOpen(false);
      }
    };
  
    window.addEventListener('keydown', handleEsc);
    return () => window.removeEventListener('keydown', handleEsc);
  }, []);

  //This function is called when the page is loaded and it grabs the group data.
  const fetchData = async () => {
    setLoading(true);
    try {
      const data: RootGroup[] = await getRootGroups();
      setRootData(data);
    } catch (error) {
      errorHandler(error);
      console.error('Error getting groups', error);
    } finally {
      setLoading(false);
    }
  };

  //Add this to the list of don't touch. This function is used to update the shaping data when a group is selected
  //Without this the shaping data will reset when a group is selected
  const updateShapingData = (group: RootGroup | null) => {
    if (group && group.shapingData && group.shapingData.YouTube) {
      setLowYT(group.shapingData.YouTube.low || { target: 0, targetDataRate: 0 });
      setMedYT(group.shapingData.YouTube.medium || { target: 0, targetDataRate: 0 });
      setHighYT(group.shapingData.YouTube.high || { target: 0, targetDataRate: 0 });
      setNoneYT(group.shapingData.YouTube.none || { target: 0, targetDataRate: 0 });
    } if(group && group.shapingData && group.shapingData.Netflix) {
      setLowNtfx(group.shapingData.Netflix.low || { target: 0, targetDataRate: 0 });
      setMedNtfx(group.shapingData.Netflix.medium || { target: 0, targetDataRate: 0 });
      setHighNtfx(group.shapingData.Netflix.high || { target: 0, targetDataRate: 0 });
      setNoneNtfx(group.shapingData.Netflix.none || { target: 0, targetDataRate: 0 });
    }
    if (group && group.shapingData && group.shapingData.Amazon) {
      setLowAmzn(group.shapingData.Amazon.low || { target: 0, targetDataRate: 0 });
      setMedAmzn(group.shapingData.Amazon.medium || { target: 0, targetDataRate: 0 });
      setHighAmzn(group.shapingData.Amazon.high || { target: 0, targetDataRate: 0 });
      setNoneAmzn(group.shapingData.Amazon.none || { target: 0, targetDataRate: 0 });
    }
    //HULU
    if (group && group.shapingData && group.shapingData.Hulu) {
      setLowHulu(group.shapingData.Hulu.low || { target: 0, targetDataRate: 0 });
      setMedHulu(group.shapingData.Hulu.medium || { target: 0, targetDataRate: 0 });
      setHighHulu(group.shapingData.Hulu.high || { target: 0, targetDataRate: 0 });
      setNoneHulu(group.shapingData.Hulu.none || { target: 0, targetDataRate: 0 });
    } 
    // Peacock
    if (group && group.shapingData && group.shapingData.Peacock) {
      setLowPck(group.shapingData.Peacock.low || { target: 0, targetDataRate: 0 });
      setMedPck(group.shapingData.Peacock.medium || { target: 0, targetDataRate: 0 });
      setHighPck(group.shapingData.Peacock.high || { target: 0, targetDataRate: 0 });
      setNonePck(group.shapingData.Peacock.none || { target: 0, targetDataRate: 0 });
    }
    //Fox Nation
    if (group && group.shapingData && group.shapingData.FoxNation) {
      setLowFox(group.shapingData.FoxNation.low || { target: 0, targetDataRate: 0 });
      setMedFox(group.shapingData.FoxNation.medium || { target: 0, targetDataRate: 0 });
      setHighFox(group.shapingData.FoxNation.high || { target: 0, targetDataRate: 0 });
      setNoneFox(group.shapingData.FoxNation.none || { target: 0, targetDataRate: 0 });
    } 
    // Paramount plus
    if (group && group.shapingData && group.shapingData.Paramount) {
      setLowPmnt(group.shapingData.Paramount.low || { target: 0, targetDataRate: 0 });
      setMedPmnt(group.shapingData.Paramount.medium || { target: 0, targetDataRate: 0 });
      setHighPmnt(group.shapingData.Paramount.high || { target: 0, targetDataRate: 0 });
      setNonePmnt(group.shapingData.Paramount.none || { target: 0, targetDataRate: 0 });
    } // Max
    if (group && group.shapingData && group.shapingData.Max) {
      setLowMax(group.shapingData.Max.low || { target: 0, targetDataRate: 0 });
      setMedMax(group.shapingData.Max.medium || { target: 0, targetDataRate: 0 });
      setHighMax(group.shapingData.Max.high || { target: 0, targetDataRate: 0 });
      setNoneMax(group.shapingData.Max.none || { target: 0, targetDataRate: 0 });
    } 
    if (group && group.shapingData && group.shapingData.Viki) {
      setLowViki(group.shapingData.Viki.low || { target: 0, targetDataRate: 0 });
      setMedViki(group.shapingData.Viki.medium || { target: 0, targetDataRate: 0 });
      setHighViki(group.shapingData.Viki.high || { target: 0, targetDataRate: 0 });
      setNoneViki(group.shapingData.Viki.none || { target: 0, targetDataRate: 0 });
    }
  };

  const handleRowClick = async (rowData: RootGroup) => {
    setNewGroupName(rowData.name || '');
    setBypassWiFiTraffic(rowData.bypassWiFiTraffic || false);
    setShapingLevels(rowData)

    try {
      const data = await getGroupInfo(rowData.name);
      setSelectedGroup(rowData);
      updateShapingData(rowData);
    } catch (error) {
      toaster.warning('Error', {description: 'Error getting group data', duration: 8});
      console.error('Error getting group data', error);
    } finally {
      setIsSideSheetOpen(true);
    }
  };

  const handleCreateClick = async (newGroup: RootGroup) => {
    setNewGroupName(newGroup.name);
    setBypassWiFiTraffic(newGroup.bypassWiFiTraffic);
    setSelectedGroup(newGroup);
    setShapingLevels(newGroup)
    setIsSideSheetOpen(true);
  }

  //This is what is used when setting the shaping levels via the dropdown box
  const setShapingLevels = (group: RootGroup) => {
      setLevelOfYT(toShapingLevel(group.shapingData?.YouTube?.target) || defaultShapingLevel);
      setLevelOfNetflix(toShapingLevel(group.shapingData?.Netflix?.target) || defaultShapingLevel);
      setLevelOfAmazon(toShapingLevel(group.shapingData?.Amazon?.target) || defaultShapingLevel);
      setLevelOfHulu(toShapingLevel(group.shapingData?.Hulu?.target) || defaultShapingLevel);
      setLevelOfPeacock(toShapingLevel(group.shapingData?.Peacock?.target) || defaultShapingLevel);
      setLevelOfFoxNation(toShapingLevel(group.shapingData?.FoxNation?.target) || defaultShapingLevel);
      setLevelOfParamount(toShapingLevel(group.shapingData?.Paramount?.target) || defaultShapingLevel);
      setLevelOfMax(toShapingLevel(group.shapingData?.Max?.target) || defaultShapingLevel);
      setLevelOfViki(toShapingLevel(group.shapingData?.Viki?.target) || defaultShapingLevel);
  }

  const handleComboboxChange = (selected: ShapingLevel, field: string) => {
    if (!selected) {
      console.warn("Selected is null");
      return;
    }
    switch (field) {
      case 'YouTube':
        setLevelOfYT(selected);
        break;
      case 'Netflix':
        setLevelOfNetflix(selected);
        break;
      case 'Amazon':
        setLevelOfAmazon(selected);
        break;
      case 'Hulu':
        setLevelOfHulu(selected);
        break;
      case 'Peacock':
        setLevelOfPeacock(selected);
        break;
      case 'FoxNation':
        setLevelOfFoxNation(selected);
        break;
      case 'Paramount':
        setLevelOfParamount(selected);
        break;
      case 'Max':
        setLevelOfMax(selected);
        break;
      case 'Viki':
        setLevelOfViki(selected);
        break;
    }
  };

  //IF YOU BREAK THIS CODE I WILL BREAK YOU
  //This save function will save the values of the group settings without resetting the shaping values
  // Please note that the single ? is short for a ternary operator and acts as a if else statement
  const handleSave = async () => {
    if (selectedGroup) {
      const formData = {
        name: newGroupName || '',
        bypassWiFiTraffic: bypassWiFiTraffic,
        levelOfSavings: selectedGroup.levelOfSavings,
        shapingData: {
          YouTube: {
            target: levelOfYT.name !== selectedGroup?.shapingData?.YouTube?.target ? levelOfYT.name : selectedGroup?.shapingData?.YouTube?.target,
            low: lowYT!== selectedGroup?.shapingData?.YouTube?.low ? lowYT : selectedGroup?.shapingData?.YouTube?.low,
            medium: medYT !== selectedGroup?.shapingData?.YouTube?.medium ? medYT : selectedGroup?.shapingData?.YouTube?.medium,
            high: highYT !== selectedGroup?.shapingData?.YouTube?.high ? highYT : selectedGroup?.shapingData?.YouTube?.high,
            none: noneYT !== selectedGroup?.shapingData?.YouTube?.none ? noneYT : selectedGroup?.shapingData?.YouTube?.none
          },
          Netflix: {
            target: levelOfNetflix.name !== selectedGroup?.shapingData?.Netflix?.target ? levelOfNetflix.name : selectedGroup?.shapingData?.Netflix?.target,
            low: lowNtfx !== selectedGroup?.shapingData?.Netflix?.low ? lowNtfx : selectedGroup?.shapingData?.Netflix?.low,
            medium: medNtfx !== selectedGroup?.shapingData?.Netflix?.medium ? medNtfx : selectedGroup?.shapingData?.Netflix?.medium,
            high: highNtfx !== selectedGroup?.shapingData?.Netflix?.high ? highNtfx : selectedGroup?.shapingData?.Netflix?.high,
            none: noneNtfx !== selectedGroup?.shapingData?.Netflix?.none ? noneNtfx : selectedGroup?.shapingData?.Netflix?.none
          },
          Amazon: {
            target: levelOfAmazon.name !== selectedGroup?.shapingData?.Amazon?.target ? levelOfAmazon.name : selectedGroup?.shapingData?.Amazon?.target,
            low: lowAmzn !== selectedGroup?.shapingData?.Amazon?.low ? lowAmzn : selectedGroup?.shapingData?.Amazon?.low,
            medium: medAmzn !== selectedGroup?.shapingData?.Amazon?.medium ? medAmzn : selectedGroup?.shapingData?.Amazon?.medium,
            high: highAmzn !== selectedGroup?.shapingData?.Amazon?.high ? highAmzn : selectedGroup?.shapingData?.Amazon?.high,
            none: noneAmzn !== selectedGroup?.shapingData?.Amazon?.none ? noneAmzn : selectedGroup?.shapingData?.Amazon?.none
          },
          Hulu: {
            target: levelOfHulu.name !== selectedGroup?.shapingData?.Hulu?.target ? levelOfHulu.name : selectedGroup?.shapingData?.Hulu?.target,
            low: lowHulu !== selectedGroup?.shapingData?.Hulu?.low ? lowHulu : selectedGroup?.shapingData?.Hulu?.low,
            medium: medHulu !== selectedGroup?.shapingData?.Hulu?.medium ? medHulu : selectedGroup?.shapingData?.Hulu?.medium,
            high: highHulu !== selectedGroup?.shapingData?.Hulu?.high ? highHulu : selectedGroup?.shapingData?.Hulu?.high,
            none: noneHulu !== selectedGroup?.shapingData?.Hulu?.none ? noneHulu : selectedGroup?.shapingData?.Hulu?.none
          },
          Peacock: {
            target: levelOfPeacock.name !== selectedGroup?.shapingData?.Peacock?.target ? levelOfPeacock.name : selectedGroup?.shapingData?.Peacock?.target,
            low: lowPck !== selectedGroup?.shapingData?.Peacock?.low ? lowPck : selectedGroup?.shapingData?.Peacock?.low,
            medium: medPck !== selectedGroup?.shapingData?.Peacock?.medium ? medPck : selectedGroup?.shapingData?.Peacock?.medium,
            high: highPck !== selectedGroup?.shapingData?.Peacock?.high ? highPck : selectedGroup?.shapingData?.Peacock?.high,
            none: nonePck !== selectedGroup?.shapingData?.Peacock?.none ? nonePck : selectedGroup?.shapingData?.Peacock?.none
          },
          FoxNation: {
            target: levelOfFoxNation.name !== selectedGroup?.shapingData?.FoxNation?.target ? levelOfFoxNation.name : selectedGroup?.shapingData?.FoxNation?.target,
            low: lowFox !== selectedGroup?.shapingData?.FoxNation?.low ? lowFox : selectedGroup?.shapingData?.FoxNation?.low,
            medium: medFox !== selectedGroup?.shapingData?.FoxNation?.medium ? medFox : selectedGroup?.shapingData?.FoxNation?.medium,
            high: highFox !== selectedGroup?.shapingData?.FoxNation?.high ? highFox : selectedGroup?.shapingData?.FoxNation?.high,
            none: noneFox !== selectedGroup?.shapingData?.FoxNation?.none ? noneFox : selectedGroup?.shapingData?.FoxNation?.none
          },
          Paramount: {
            target: levelOfParamount.name !== selectedGroup?.shapingData?.Paramount?.target ? levelOfParamount.name : selectedGroup?.shapingData?.Paramount?.target,
            low: lowPmnt !== selectedGroup?.shapingData?.Paramount?.low ? lowPmnt : selectedGroup?.shapingData?.Paramount?.low,
            medium: medPmnt !== selectedGroup?.shapingData?.Paramount?.medium ? medPmnt : selectedGroup?.shapingData?.Paramount?.medium,
            high: highPmnt !== selectedGroup?.shapingData?.Paramount?.high ? highPmnt : selectedGroup?.shapingData?.Paramount?.high,
            none: nonePmnt !== selectedGroup?.shapingData?.Paramount?.none ? nonePmnt : selectedGroup?.shapingData?.Paramount?.none
          },
          Max: {
            target: levelOfMax.name !== selectedGroup?.shapingData?.Max?.target ? levelOfMax.name : selectedGroup?.shapingData?.Max?.target,
            low: lowMax !== selectedGroup?.shapingData?.Max?.low ? lowMax : selectedGroup?.shapingData?.Max?.low,
            medium: medMax !== selectedGroup?.shapingData?.Max?.medium ? medMax : selectedGroup?.shapingData?.Max?.medium,
            high: highMax !== selectedGroup?.shapingData?.Max?.high ? highMax : selectedGroup?.shapingData?.Max?.high,
            none: noneMax !== selectedGroup?.shapingData?.Max?.none ? noneMax : selectedGroup?.shapingData?.Max?.none
          },
          Viki: {
            target: levelOfViki.name !== selectedGroup?.shapingData?.Viki?.target ? levelOfViki.name : selectedGroup?.shapingData?.Viki?.target,
            low: lowViki !== selectedGroup?.shapingData?.Viki?.low ? lowViki : selectedGroup?.shapingData?.Viki?.low,
            medium: medViki !== selectedGroup?.shapingData?.Viki?.medium ? medViki : selectedGroup?.shapingData?.Viki?.medium,
            high: highViki !== selectedGroup?.shapingData?.Viki?.high ? highViki : selectedGroup?.shapingData?.Viki?.high,
            none: noneViki !== selectedGroup?.shapingData?.Viki?.none ? noneViki : selectedGroup?.shapingData?.Viki?.none
          }
        }
      };

      //This is the try catch block that will try and send the group data to the server
      // If the server returns an error it will display a toaster message with the error
      try {
        if (selectedGroup.name) {
          await updateGroup({ oldName: selectedGroup.name, groupData: formData });
        } else {
          await createGroup(formData);
        }
        toaster.success('Group updated successfully', { duration: 8 });
        fetchData();
        setIsSideSheetOpen(false);
        resetShapingData();
      } catch (error: any) {
        if (error.response) {
          switch (error.response.status) {
            case 422:
              toaster.warning('Invalid entry', { description: 'One or more fields are invalid. Please check and try again.', duration: 8 });
              break;
            case 409:
              toaster.warning('Invalid entry', { description: 'Group name already exists', duration: 8 });
              break;
            default:
              toaster.danger('Error updating group', { description: error.response.data.message, duration: 8 });
          }
        } else {
          errorHandler(error);
        }
      }
    }
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);
    setCurrentPage(1);
  };

  const filteredData = rootData.filter(group =>
    group.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = filteredData.slice(startIndex, endIndex);

  if (loading) {
    return (
      <LoadingWord />
    );
  }

  return (
    <Pane>
      <Pane display="flex" justifyContent="flex-end" marginBottom={majorScale(2)}>
        <button className="Btn" onClick={() => handleCreateClick(newGroup)}>
          <div className="sign">+</div>
          <div className="text">Create</div>
        </button>
      </Pane>
      {/*---------------------------------------------
        This is the table that displays the group data. It is a table that displays the group name, connection policy, and default shaping level.
        The table is also searchable and paginated.
      ---------------------------------------------*/}
      <Table className="table">
        <Table.Head className="table-header">
          <Table.SearchHeaderCell onChange={handleSearch} placeholder="Search groups..." />
          <Table.TextHeaderCell>Connection Policy</Table.TextHeaderCell>
          <Table.TextHeaderCell>Default Shaping Level</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {currentItems.map((group, index) => (
            <Table.Row className="table-row" key={index} isSelectable onSelect={() => handleRowClick(group)}>
              <Table.TextCell className="table-cell">{group.name}</Table.TextCell>
              <Table.TextCell className="table-cell">{group.bypassWiFiTraffic ? "Cellular only" : "Cellular and WiFi"}</Table.TextCell>
              <Table.TextCell className="table-cell">{group.levelOfSavings}</Table.TextCell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <Pagination
        page={currentPage}
        totalPages={Math.ceil(filteredData.length / itemsPerPage)}
        onPageChange={(page: number) => setCurrentPage(page)}
      />
      {/*---------------------------------------------
        This is the side sheet that pops up when a group is clicked. It displays the group details and allows the user to edit the group settings.
        The side sheet also has a save and cancel button.
      ---------------------------------------------*/}
      <SideSheet
        isShown={isSideSheetOpen}
        onCloseComplete={() => setIsSideSheetOpen(false)}
      >
        <Pane padding={16} borderBottom="muted">
          <Heading size={600}>Group Details</Heading>
        </Pane>
        <Pane padding={16}>
          {selectedGroup && (
            <>
              <Paragraph><strong className="large_text">Name:</strong></Paragraph>
              <TextInput
                value={newGroupName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewGroupName(e.target.value)}
                marginBottom={16}
              />
              <Paragraph><strong className="large_text">Connection Policy:</strong></Paragraph>
              <Pane display="flex" alignItems="center" marginBottom={16}>
                <Text color="muted">Cellular and WiFi</Text>
                {/* This is disabled so the user cannot change the value, to change this in the future remove the last line of the switch */}
                <Switch
                  checked={true}
                  onChange={() => {}}
                  height={24}
                  marginLeft={8}
                  marginRight={8}
                  disabled={true}
                />
                <Text>Cellular only</Text>
              </Pane>
              <Paragraph><strong className="large_text">Default Shaping Level:</strong> </Paragraph>
            </>
          )}

          <Pane>
            <Pane>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text style={{ fontWeight: 'semi-bold' }} marginRight={8} marginLeft={16}>
                  YouTube
                </Text>
                {/* This is the advanced settings button, that will only show up if the correct env var is set. */}
                <AdvSettings 
                  currentService="YouTube"
                  currentGroup={selectedGroup!}
                  setShaping={[setLowYT, setMedYT, setHighYT, setNoneYT]}
                />
              </div>
              <Combobox
                // items={shapingLevels}
                // selectedItem={levelOfYT}
                // onChange={(selected) => handleComboboxChange(selected, 'YouTube')}
                // itemToString={(item) => item.label }
                // marginLeft={8}
                items={shapingLevels.filter(level => level.name === 'low' || level.name === "none")}
                selectedItem={
                  levelOfYT.name === 'low' ? LowShapingLevel : 
                  levelOfYT.name === 'none' ? NoneShapingLevel : levelOfYT
                }
                onChange={(selected) => {
                  handleComboboxChange(selected, 'YouTube')
                }}
                itemToString={(item) => item.label}
                marginLeft={8}
              />
            </Pane>
          </Pane>

          <Pane>
            <Pane>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text style={{ fontWeight: 'semi-bold' }} marginRight={8} marginLeft={16}>
                  Netflix
                </Text>
                {/* This is the advanced settings button, that will only show up if the correct env var is set. */}
                <AdvSettings  
                  currentService="Netflix"
                  currentGroup={selectedGroup!}
                  setShaping={[setLowNtfx, setMedNtfx, setHighNtfx, setNoneNtfx]}
                />
              </div>
              <Combobox
                // items={shapingLevels}
                // selectedItem={levelOfNetflix}
                // onChange={(selected) => handleComboboxChange(selected, 'Netflix')}
                // itemToString={(item) => item.label }
                // marginLeft={8}
                items={shapingLevels.filter(level => level.name === 'low' || level.name === "none")}
                selectedItem={
                  levelOfNetflix.name === 'low' ? LowShapingLevel : 
                  levelOfNetflix.name === 'none' ? NoneShapingLevel : levelOfNetflix
                }
                onChange={(selected) => {
                  handleComboboxChange(selected, 'Netflix')
                }}
                itemToString={(item) => item.label}
                marginLeft={8}
              />
            </Pane>
          </Pane>

          <Pane>
            <Pane>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text style={{ fontWeight: 'semi-bold' }} marginRight={8} marginLeft={16}>
                  Amazon Prime Video
                </Text>
                {/* This is the advanced settings button, that will only show up if the correct env var is set. */}
                <AdvSettings 
                  currentService="Amazon"
                  currentGroup={selectedGroup!}
                  setShaping={[setLowAmzn, setMedAmzn, setHighAmzn, setNoneAmzn]}
                />
              </div>
              <Combobox
                // items={shapingLevels}
                // selectedItem={levelOfAmazon}
                // onChange={(selected) => handleComboboxChange(selected, 'Amazon')}
                // itemToString={(item) => item.label }
                // marginLeft={8}
                items={shapingLevels.filter(level => level.name === 'low' || level.name === "none")}
                selectedItem={
                  levelOfAmazon.name === 'low' ? LowShapingLevel : 
                  levelOfAmazon.name === 'none' ? NoneShapingLevel : levelOfAmazon
                }
                onChange={(selected) => {
                  handleComboboxChange(selected, 'Amazon')
                }}
                itemToString={(item) => item.label}
                marginLeft={8}
              />
            </Pane>
          </Pane>

          <Pane>
            <Pane>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text style={{ fontWeight: 'semi-bold' }} marginRight={8} marginLeft={16}>
                  Hulu
                </Text>
                {/* This is the advanced settings button, that will only show up if the correct env var is set. */}
                <AdvSettings 
                  currentService="Hulu"
                  currentGroup={selectedGroup!}
                  setShaping={[setLowHulu, setMedHulu, setHighHulu, setNoneHulu]}
                />
              </div>
              <Combobox
                // items={shapingLevels}
                // selectedItem={levelOfHulu}
                // onChange={(selected) => handleComboboxChange(selected, 'Hulu')}
                // itemToString={(item) => item.label }
                // marginLeft={8}
                items={shapingLevels.filter(level => level.name === 'low' || level.name === "none")}
                selectedItem={
                  levelOfHulu.name === 'low' ? LowShapingLevel : 
                  levelOfHulu.name === 'none' ? NoneShapingLevel : levelOfHulu
                }
                onChange={(selected) => {
                  handleComboboxChange(selected, 'Hulu')
                }}
                itemToString={(item) => item.label}
                marginLeft={8}
              />
            </Pane>
          </Pane>

          <Pane>
            <Pane>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text style={{ fontWeight: 'semi-bold' }} marginRight={8} marginLeft={16}>
                  Peacock
                </Text>
                {/* This is the advanced settings button, that will only show up if the correct env var is set. */}
                <AdvSettings 
                  currentService="Peacock"
                  currentGroup={selectedGroup!}
                  setShaping={[setLowPck, setMedPck, setHighPck, setNonePck]}
                />
              </div>
              <Combobox
                // items={shapingLevels}
                // selectedItem={levelOfPeacock}
                // onChange={(selected) => handleComboboxChange(selected, 'Peacock')}
                // itemToString={(item) => item.label }
                // marginLeft={8}
                items={shapingLevels.filter(level => level.name === 'low' || level.name === "none")}
                selectedItem={
                  levelOfPeacock.name === 'low' ? LowShapingLevel : 
                  levelOfPeacock.name === 'none' ? NoneShapingLevel : levelOfPeacock
                }
                onChange={(selected) => {
                  handleComboboxChange(selected, 'Peacock')
                }}
                itemToString={(item) => item.label}
                marginLeft={8}
              />
            </Pane>
          </Pane>

          <Pane>
            <Pane>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text style={{ fontWeight: 'semi-bold' }} marginRight={8} marginLeft={16}>
                  Fox Nation
                </Text>
                {/* This is the advanced settings button, that will only show up if the correct env var is set. */}
                <AdvSettings 
                  currentService="FoxNation"
                  currentGroup={selectedGroup!}
                  setShaping={[setLowFox, setMedFox, setHighFox, setNoneFox]}
                />
              </div>
              <Combobox
                // items={shapingLevels}
                // selectedItem={levelOfFoxNation}
                // onChange={(selected) => handleComboboxChange(selected, 'Peacock')}
                // itemToString={(item) => item.label }
                // marginLeft={8}
                items={shapingLevels.filter(level => level.name === 'low' || level.name === "none")}
                selectedItem={
                  levelOfFoxNation.name === 'low' ? LowShapingLevel : 
                  levelOfFoxNation.name === 'none' ? NoneShapingLevel : levelOfFoxNation
                }
                onChange={(selected) => {
                  handleComboboxChange(selected, 'FoxNation')
                }}
                itemToString={(item) => item.label}
                marginLeft={8}
              />
            </Pane>
          </Pane>

          <Pane>
            <Pane>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text style={{ fontWeight: 'semi-bold' }} marginRight={8} marginLeft={16}>
                  Paramount +
                </Text>
                {/* This is the advanced settings button, that will only show up if the correct env var is set. */}
                <AdvSettings 
                  currentService="Paramount"
                  currentGroup={selectedGroup!}
                  setShaping={[setLowPmnt, setMedPmnt, setHighPmnt, setNonePmnt]}
                />
              </div>
              <Combobox
                // items={shapingLevels}
                // selectedItem={levelOfParamount}
                // onChange={(selected) => handleComboboxChange(selected, 'Paramount')}
                // itemToString={(item) => item.label }
                // marginLeft={8}
                items={shapingLevels.filter(level => level.name === 'low' || level.name === "none")}
                selectedItem={
                  levelOfParamount.name === 'low' ? LowShapingLevel : 
                  levelOfParamount.name === 'none' ? NoneShapingLevel : levelOfParamount
                }
                onChange={(selected) => {
                  handleComboboxChange(selected, 'Paramount')
                }}
                itemToString={(item) => item.label}
                marginLeft={8}
              />
            </Pane>
          </Pane>

          <Pane>
            <Pane>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text style={{ fontWeight: 'semi-bold' }} marginRight={8} marginLeft={16}>
                  Max
                </Text>
                {/* This is the advanced settings button, that will only show up if the correct env var is set. */}
                <AdvSettings 
                  currentService="Max"
                  currentGroup={selectedGroup!}
                  setShaping={[setLowMax, setMedMax, setHighMax, setNoneMax]}
                />
              </div>
              <Combobox
                // items={shapingLevels}
                // selectedItem={levelOfParamount}
                // onChange={(selected) => handleComboboxChange(selected, 'Paramount')}
                // itemToString={(item) => item.label }
                // marginLeft={8}
                items={shapingLevels.filter(level => level.name === 'low' || level.name === "none")}
                selectedItem={
                  levelOfMax.name === 'low' ? LowShapingLevel : 
                  levelOfMax.name === 'none' ? NoneShapingLevel : levelOfMax
                }
                onChange={(selected) => {
                  handleComboboxChange(selected, 'Max')
                }}
                itemToString={(item) => item.label}
                marginLeft={8}
              />
            </Pane>
          </Pane>

          <Pane>
            <Pane>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text style={{ fontWeight: 'semi-bold' }} marginRight={8} marginLeft={16}>
                  Viki
                </Text>
                {/* This is the advanced settings button, that will only show up if the correct env var is set. */}
                <AdvSettings  
                  currentService="Viki"
                  currentGroup={selectedGroup!}
                  setShaping={[setLowViki, setMedViki, setHighViki, setNoneViki]}
                />
              </div>
              <Combobox
                // items={shapingLevels}
                // selectedItem={levelOfViki}
                // onChange={(selected) => handleComboboxChange(selected, 'Viki')}
                // itemToString={(item) => item.label }
                // marginLeft={8}
                items={shapingLevels.filter(level => level.name === 'low' || level.name === "none")}
                selectedItem={
                  levelOfViki.name === 'low' ? LowShapingLevel : 
                  levelOfViki.name === 'none' ? NoneShapingLevel : levelOfViki
                }
                onChange={(selected) => {
                  handleComboboxChange(selected, 'Viki')
                }}
                itemToString={(item) => item.label}
                marginLeft={8}
              />
            </Pane>
          </Pane>
          {/* If we need to add more services add them here following the examples above */}
        </Pane>

        <Pane padding={16}>
          <button
            className="button-green button-padding"
            onClick={handleSave}
          >
            Save
          </button>
          <button
            className="button-red button-padding"
            onClick={() => setIsSideSheetOpen(false)}
          >
            Cancel
          </button>
        </Pane>
      </SideSheet>
    </Pane>
  );
}

export default GroupRootList;


