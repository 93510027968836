import { apiEnv } from "../../constants/api";
import { TextInput, Paragraph, Pane, Text, Dialog, Button, toaster } from "evergreen-ui";
import React, { useState } from 'react';
import { RootGroup, shapingData } from "../../models/models";
import "./styles.css";


const setRateValue = (value: number | undefined): number | undefined => {
    if (value === undefined) {
        return undefined;
    }

    return value.valueOf();
}

const AdvSettings = ({ currentService, currentGroup, setShaping } : { currentService: keyof shapingData; currentGroup: RootGroup; setShaping?: any }) => {
    const [isShown, setIsShown] = useState(false);
    const [lowBitrate, setLowBitrate] = useState(setRateValue(currentGroup.shapingData?.[currentService]?.low?.target));
    const [lowDataRate, setLowDataRate] = useState(setRateValue(currentGroup.shapingData?.[currentService]?.low?.targetDataRate));
    const [mediumBitrate, setMediumBitrate] = useState(setRateValue(currentGroup.shapingData?.[currentService]?.medium?.target));
    const [mediumDataRate, setMediumDataRate] = useState(setRateValue(currentGroup.shapingData?.[currentService]?.medium?.targetDataRate));
    const [highBitrate, setHighBitrate] = useState(setRateValue(currentGroup.shapingData?.[currentService]?.high?.target));
    const [highDataRate, setHighDataRate] = useState(setRateValue(currentGroup.shapingData?.[currentService]?.high?.targetDataRate));
    const [noneBitrate, setNoneBitrate] = useState(setRateValue(currentGroup.shapingData?.[currentService]?.none?.target));
    const [noneDataRate, setNoneDataRate] = useState(setRateValue(currentGroup.shapingData?.[currentService]?.none?.targetDataRate));

    const handleSave = async () => {
        if (
          lowBitrate === undefined || lowBitrate === null || Number.isNaN(lowBitrate) ||
          lowDataRate === undefined || lowDataRate === null || Number.isNaN(lowDataRate) ||
          mediumBitrate === undefined || mediumBitrate === null || Number.isNaN(mediumBitrate) ||
          mediumDataRate === undefined || mediumDataRate === null || Number.isNaN(mediumDataRate) ||
          highBitrate === undefined || highBitrate === null || Number.isNaN(highBitrate) ||
          highDataRate === undefined || highDataRate === null || Number.isNaN(highDataRate) ||
          noneBitrate === undefined || noneBitrate === null || Number.isNaN(noneBitrate) ||
          noneDataRate === undefined || noneDataRate === null || Number.isNaN(noneDataRate)
        ) {
          toaster.danger("Please fill out all fields", { duration: 8 });
          return;
        } else if (
          lowBitrate >= 0 && lowDataRate >= 0 &&
          mediumBitrate >= 0 && mediumDataRate >= 0 &&
          highBitrate >= 0 && highDataRate >= 0 &&
          noneBitrate >= 0 && noneDataRate >= 0
        ) {
          // Check if all values are positive if they are then save the values
          const [lowSh, medSh, highSh, noneSh] = setShaping;
          lowSh({ target: lowBitrate, targetDataRate: lowDataRate });
          medSh({ target: mediumBitrate, targetDataRate: mediumDataRate });
          highSh({ target: highBitrate, targetDataRate: highDataRate });
          noneSh({ target: noneBitrate, targetDataRate: noneDataRate });
          toaster.success("Values saved successfully", { duration: 8 });
          setIsShown(false);
        }
      };

    //DON'T TOUCH THIS IT'S JUST FOR INTERNAL USE ONLY
    //This is a check to see if the environment is in internal mode. If it is then show the advanced settings.
    if (apiEnv !== 'internal') {
        return null;
    }
    return (
        <div>
            {/* This is the advanced settings button */}
            <span className="advancedText" onClick={() => setIsShown(true)}>Shaping Values</span>
            <Dialog
                isShown={isShown}
                title={`${currentService} Shaping Values`}
                onCloseComplete={() => setIsShown(false)}
                hasFooter={true}
                footer={
                    <Button onClick={handleSave}>Save</Button>
                }
            >
                <Pane>
                    {/* This is the advanced settings form */}
                    {/* Check the text in the <Paragraph> and <Text> to get a better idea of what value you are looking at. */}
                    <Paragraph>
                        <strong className="large_text">Low:</strong>
                    </Paragraph>
                    <Pane display="flex" alignItems="center" marginY={16} paddingLeft={16}>
                        <Text style={{ fontWeight: '600', marginRight: 16 }}>Target Bitrate</Text>
                        <TextInput
                            type="number"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLowBitrate(parseInt(e.target.value))}
                            placeholder="Enter Bitrate"
                            name="lowBitrate"
                            value={lowBitrate}
                        />
                    </Pane>
                    <Pane display="flex" alignItems="center" marginY={16} paddingLeft={16}>
                        <Text style={{ fontWeight: '600', marginRight: 16 }}>Target Data Rate</Text>
                        <TextInput
                            type="number"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLowDataRate(parseInt(e.target.value))}
                            placeholder="Enter Target"
                            name="lowDataRate"
                            value={lowDataRate}
                        />
                    </Pane>
                    <Paragraph>
                        <strong className="large_text">Medium:</strong>
                    </Paragraph>
                    <Pane display="flex" alignItems="center" marginY={16} paddingLeft={16}>
                        <Text style={{ fontWeight: '600', marginRight: 16 }}>Target Bitrate</Text>
                        <TextInput
                            type="number"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMediumBitrate(parseInt(e.target.value))}
                            placeholder="Enter Bitrate"
                            name="mediumBitrate"
                            value={mediumBitrate}
                        />
                    </Pane>
                    <Pane display="flex" alignItems="center" marginY={16} paddingLeft={16}>
                        <Text style={{ fontWeight: '600', marginRight: 16 }}>Target Data Rate</Text>
                        <TextInput
                            type="number"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMediumDataRate(parseInt(e.target.value))}
                            placeholder="Enter Target"
                            name="mediumDataRate"
                            value={mediumDataRate}
                        />
                    </Pane>
                    <Paragraph>
                        <strong className="large_text">High:</strong>
                    </Paragraph>
                    <Pane display="flex" alignItems="center" marginY={16} paddingLeft={16}>
                        <Text style={{ fontWeight: '600', marginRight: 16 }}>Target Bitrate</Text>
                        <TextInput
                            type="number"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setHighBitrate(parseInt(e.target.value))}
                            placeholder="Enter Bitrate"
                            name="highBitrate"
                            value={highBitrate}
                        />
                    </Pane>
                    <Pane display="flex" alignItems="center" marginY={16} paddingLeft={16}>
                        <Text style={{ fontWeight: '600', marginRight: 16 }}>Target Data Rate</Text>
                        <TextInput
                            type="number"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setHighDataRate(parseInt(e.target.value))}
                            placeholder="Enter Target"
                            name="highDataRate"
                            value={highDataRate}
                        />
                    </Pane>
                    <Paragraph>
                        <strong className="large_text">None:</strong>
                    </Paragraph>
                    <Pane display="flex" alignItems="center" marginY={16} paddingLeft={16}>
                        <Text style={{ fontWeight: '600', marginRight: 16 }}>Target Bitrate</Text>
                        <TextInput
                            type="number"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNoneBitrate(parseInt(e.target.value))}
                            placeholder="Enter Bitrate"
                            name="noneBitrate"
                            value={noneBitrate}
                        />
                    </Pane>
                    <Pane display="flex" alignItems="center" marginY={16} paddingLeft={16}>
                        <Text style={{ fontWeight: '600', marginRight: 16 }}>Target Data Rate</Text>
                        <TextInput
                            type="number"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNoneDataRate(parseInt(e.target.value))}
                            placeholder="Enter Target"
                            name="noneDataRate"
                            value={noneDataRate}
                        />
                    </Pane>
                </Pane>
            </Dialog>
        </div>
    )
}

export default AdvSettings;