"use client";

import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, LineElement, CategoryScale, LinearScale, Title, Tooltip, Legend, PointElement } from 'chart.js';
import Loader from '../loader/loader';
import './other.css';
import '../../dashboard/dashStyles.css';

Chart.register(LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

interface LineChartComponentProps {
    groupData: any;
    selectedGroup: string;
    startDate: Date | null;
    endDate: Date | null;
    loading: boolean;
}

const LineChartComponent: React.FC<LineChartComponentProps> = ({ groupData, selectedGroup, startDate, endDate, loading }) => {
    const [chartData, setChartData] = useState<any>(null);
    const chartRef = useRef<any>(null);

    useEffect(() => {
        if (selectedGroup && startDate && endDate) {
            const fetchData = async () => {
                const filteredData = groupData.filter((item: any) => {
                    const itemDate = new Date(item.startTime);
                    return itemDate >= startDate && itemDate <= endDate;
                });
                const processedData = processChartData(filteredData);
                setChartData(processedData);
            };
            fetchData();
        }
    }, [groupData, selectedGroup, startDate, endDate]);

    useEffect(() => {
        const resizeChart = () => {
            if (chartRef.current && chartRef.current.chartInstance) {
                chartRef.current.chartInstance.resize();
            }
        };

        window.addEventListener('resize', resizeChart);

        return () => {
            window.removeEventListener('resize', resizeChart);
        };
    }, []);

    const processChartData = (data: any) => {
        const keywordMapping: { [key: string]: string } = {
            'aiv-delivery.net': 'Prime Video',
            'amazonvideo.com': 'Prime Video',
            'Prime Video': 'Prime Video',
            'amazon': 'Prime Video',
            'nflxvideo.net': 'Netflix',
            'netflix.com': 'Netflix',
            'netflix': 'Netflix',
            'Netflix': 'Netflix',
            'logs.netflix.com': 'Netflix',
            'android.prod.ftl.netflix.com': 'Netflix',
            'android-appboot.netflix.com': 'Netflix',
            'tiktokv.us': 'Tiktok',
            'TikTok': 'TikTok',
            'viki.io': 'Viki',
            'googlevideo': 'YouTube',
            'youtube.com': 'YouTube',
            'youtube': 'YouTube',
            'Youtube': 'YouTube',
            'YouTube': 'YouTube',
            'Paramount+': 'Paramount+',
            'Fox Nation': 'Fox Nation',
            'Peacock': 'Peacock',
            'Hulu': 'Hulu',
            'MAX': 'Max',
            'Max': 'Max',
        };

        const cssVars = getComputedStyle(document.documentElement);
        const colorMapping: { [key: string]: { background: string, border: string } } = {
            'YouTube': {
                background: cssVars.getPropertyValue('--youtube-red'),
                border: cssVars.getPropertyValue('--youtube-border'),
            },
            'Netflix': {
                background: cssVars.getPropertyValue('--netflix-red'),
                border: cssVars.getPropertyValue('--netflix-border'),
            },
            'Prime Video': {
                background: cssVars.getPropertyValue('--amazon-blue'),
                border: cssVars.getPropertyValue('--amazon-border'),
            },
            'Viki': {
                background: cssVars.getPropertyValue('--viki-blue'),
                border: cssVars.getPropertyValue('--viki-border'),
            },
            'TikTok': {
                background: cssVars.getPropertyValue('--tiktok-blue'),
                border: cssVars.getPropertyValue('--tiktok-border'),
            },
            'Hulu': {
                background: cssVars.getPropertyValue('--hulu-green'),
                border: cssVars.getPropertyValue('--hulu-border'),
            },
            'Peacock': {
                background: cssVars.getPropertyValue('--peacock-yellow'),
                border: cssVars.getPropertyValue('--peacock-border'),
            },
            'Fox Nation': {
                background: cssVars.getPropertyValue('--foxNation'),
                border: cssVars.getPropertyValue('--foxNation-border'),
            },
            'Paramount+': {
                background: cssVars.getPropertyValue('--paramount-blue'),
                border: cssVars.getPropertyValue('--paramount-border'),
            },
            'Max': {
                background: cssVars.getPropertyValue('--max-purple'),
                border: cssVars.getPropertyValue('--max-border'),
            },
            'Other': {
                background: cssVars.getPropertyValue('--other-color'),
                border: cssVars.getPropertyValue('--other-boarder'),
            },
        };

        const aggregatedData: { [date: string]: { [service: string]: number } } = {};
        const servicesSet = new Set<string>();

        data.forEach((item: any) => {
            const itemDate = new Date(item.startTime).toISOString().split('T')[0]; // Get date in YYYY-MM-DD format
            const dataUsage = item.dataUsage;

            let domainGroup = 'Other';
            for (const keyword in keywordMapping) {
                if (item.nameConf.includes(keyword)) {
                    domainGroup = keywordMapping[keyword];
                    break;
                }
            }

            if (!aggregatedData[itemDate]) {
                aggregatedData[itemDate] = {};
            }

            if (!aggregatedData[itemDate][domainGroup]) {
                aggregatedData[itemDate][domainGroup] = 0;
            }

            aggregatedData[itemDate][domainGroup] += dataUsage;
            servicesSet.add(domainGroup);
        });

        const labels = Object.keys(aggregatedData).sort();
        const services = Array.from(servicesSet);
        const datasets = services.map(service => {
            const serviceData = labels.map(label => aggregatedData[label][service] || 0);
            return {
                label: service,
                data: serviceData,
                backgroundColor: colorMapping[service]?.background || 'rgba(0, 0, 0, 0.2)',
                borderColor: colorMapping[service]?.border || 'rgba(0, 0, 0, 1)',
                borderWidth: 1,
                fill: false,
                tension: 0.1,
            };
        });

        return {
            labels,
            datasets,
        };
    };

    const formatBytes = (bytes: number, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1000;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    if (loading) {
        return <div className="centered-container"><Loader /></div>;
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false, // Allows the chart to stretch to fit its container
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 1000 * 1000 * 1000,
                    callback: function(value: any) {
                        return formatBytes(value);
                    }
                }
            }
        },
        plugins: {
            legend: {
                position: 'top' as const,
            },
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        const label = context.dataset.label || '';
                        const value = context.raw;
                        return `${label}: ${formatBytes(value)}`;
                    },
                },
            },
            datalabels: {
                display: false,
            }
        },
    };

    return (
        <div className="grid-item" style={{ width: '100%', height: '100%' }}>
            {chartData ? (
                <Line ref={chartRef} data={chartData} options={options} />
            ) : null }
        </div>
    );
};

export default LineChartComponent;
