"use client";

import React, { useState, useEffect } from 'react';
import { getGroupData } from '../../api/shared';
import Loader from '../loader/loader';
import './other.css';

interface MostUsedComponentProps {
    groupData: any;
    selectedGroup: string;
    startDate: Date | null;
    endDate: Date | null;
    loadingClear: boolean;
}

const keywordMapping: { [key: string]: string } = {
    'googlevideo': 'YouTube',
    'nflxvideo.net': 'Netflix',
    'aiv-delivery.net': 'Amazon',
    'netflix.com': 'Netflix',
    'youtube.com': 'YouTube',
    'viki.io': 'Viki',
    'youtube': 'YouTube',
    'netflix': 'Netflix',
    'amazonvideo.com': 'Amazon',
    'YouTube': 'YouTube',
    'tiktokv.us': 'Tiktok',
    'TikTok': 'TikTok',
    'Netflix': 'Netflix',
    'Youtube': 'YouTube',
    'Prime Video': 'Amazon',
    'amazon': 'Amazon',
    'Paramount+': 'Paramount+',
    'Fox Nation': 'Fox Nation',
    'Peacock': 'Peacock',
    'Hulu': 'Hulu',
    'MAX': 'Max',
};

const MostUsedComponent: React.FC<MostUsedComponentProps> = ({ groupData, selectedGroup, startDate, endDate, loadingClear }) => {
    const [data, setData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [mostUsedService, setMostUsedService] = useState<string>('');

    useEffect(() => {
        const fetchData = async () => {
            if (selectedGroup) {
                setLoading(true);
                try {
                    const response = groupData;
                    response.forEach((item: any) => {
                        const startTime = item.startTime ? new Date(item.startTime) : "No Start Time";
                        const stopTime = item.stopTime ? new Date(item.stopTime) : "No Stop Time";
                    });

                    const filteredData = response.filter((item: any) => {
                        if (!item.startTime && !item.stopTime) {
                            return false;
                        }
                        const itemStartTime = new Date(item.startTime);
                        const itemStopTime = new Date(item.stopTime);
                        return (
                            (!startDate || itemStartTime >= startDate) &&
                            (!endDate || itemStopTime <= endDate)
                        );
                    });
                    setData(filteredData);
                    setLoading(false);

                    if (filteredData && filteredData.length > 0) {
                        // Calculate the most used service
                        const serviceUsage = filteredData.reduce((acc: any, curr: any) => {
                            const serviceName = Object.keys(keywordMapping).find(key => curr.domainConf?.includes(key) || curr.nameConf?.includes(key));
                            if (serviceName) {
                                const mappedName = keywordMapping[serviceName];
                                acc[mappedName] = (acc[mappedName] || 0) + (curr.dataUsage || 0);
                            }
                            return acc;
                        }, {});
                        const mostUsed = Object.keys(serviceUsage).reduce((a, b) => serviceUsage[a] > serviceUsage[b] ? a : b, '');
                        setMostUsedService(mostUsed);
                    } else {
                        setMostUsedService("No Data");
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                    setLoading(false);
                }
            }
        };

        fetchData();
    }, [groupData, selectedGroup, startDate, endDate]);

    if (loading) {
        return <p className='device-count'>N/A</p>;
    }

    if (loadingClear) {
        return <p className='device-count'>Loading...</p>;
    }

    return (
        <div>
            {/* ignore the className, it just follows the same style as the other components */}
           <p className="device-count">{mostUsedService}</p>
        </div>
    );
}

export default MostUsedComponent;
