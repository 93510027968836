"use client";

import React, { useState, useEffect, useRef } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Loader from '../loader/loader';
import './other.css';
import '../../dashboard/dashStyles.css';

Chart.register(ArcElement, CategoryScale, LinearScale, Title, Tooltip, Legend, ChartDataLabels);

interface PieChartComponentProps {
    groupData: any;
    selectedGroup: string;
    startDate: Date | null;
    endDate: Date | null;
    loading: boolean;
}

const PieChartComponent: React.FC<PieChartComponentProps> = ({ groupData, selectedGroup, startDate, endDate, loading }) => {
    const [chartData, setChartData] = useState<any>(null);
    const chartRef = useRef<any>(null);

    useEffect(() => {
        if (selectedGroup && startDate && endDate) {
            const fetchData = async () => {
                const filteredData = groupData.filter((item: any) => {
                    const itemDate = new Date(item.startTime);
                    return itemDate >= startDate && itemDate <= endDate;
                });
                const processedData = processChartData(filteredData);
                setChartData(processedData);
            };
            fetchData();
        }
    }, [groupData, selectedGroup, startDate, endDate]);

    useEffect(() => {
        const resizeChart = () => {
            if (chartRef.current && chartRef.current.chartInstance) {
                chartRef.current.chartInstance.resize();
            }
        };

        window.addEventListener('resize', resizeChart);

        return () => {
            window.removeEventListener('resize', resizeChart);
        };
    }, []);

    const processChartData = (data: any) => {
        const keywordMapping: { [key: string]: string } = {
            'aiv-delivery.net': 'Prime Video',
            'amazonvideo.com': 'Prime Video',
            'Prime Video': 'Prime Video',
            'amazon': 'Prime Video',
            'nflxvideo.net': 'Netflix',
            'netflix.com': 'Netflix',
            'netflix': 'Netflix',
            'Netflix': 'Netflix',
            'logs.netflix.com': 'Netflix',
            'android.prod.ftl.netflix.com': 'Netflix',
            'android-appboot.netflix.com': 'Netflix',
            'tiktokv.us': 'Tiktok',
            'TikTok': 'TikTok',
            'viki.io': 'Viki',
            'googlevideo': 'YouTube',
            'youtube.com': 'YouTube',
            'youtube': 'YouTube',
            'Youtube': 'YouTube',
            'YouTube': 'YouTube',
            'Paramount+': 'Paramount+',
            'Fox Nation': 'Fox Nation',
            'Peacock': 'Peacock',
            'Hulu': 'Hulu',
            'MAX': 'Max',
            'Max': 'Max',
        };

        const cssVars = getComputedStyle(document.documentElement);
        const colorMapping: { [key: string]: { background: string, border: string } } = {
            'YouTube': {
                background: cssVars.getPropertyValue('--youtube-red'),
                border: cssVars.getPropertyValue('--youtube-border'),
            },
            'Netflix': {
                background: cssVars.getPropertyValue('--netflix-red'),
                border: cssVars.getPropertyValue('--netflix-border'),
            },
            'Prime Video': {
                background: cssVars.getPropertyValue('--amazon-blue'),
                border: cssVars.getPropertyValue('--amazon-border'),
            },
            'Viki': {
                background: cssVars.getPropertyValue('--viki-blue'),
                border: cssVars.getPropertyValue('--viki-border'),
            },
            'TikTok': {
                background: cssVars.getPropertyValue('--tiktok-blue'),
                border: cssVars.getPropertyValue('--tiktok-border'),
            },
            'Hulu': {
                background: cssVars.getPropertyValue('--hulu-green'),
                border: cssVars.getPropertyValue('--hulu-border'),
            },
            'Peacock': {
                background: cssVars.getPropertyValue('--peacock-yellow'),
                border: cssVars.getPropertyValue('--peacock-border'),
            },
            'Fox Nation': {
                background: cssVars.getPropertyValue('--foxNation'),
                border: cssVars.getPropertyValue('--foxNation-border'),
            },
            'Paramount+': {
                background: cssVars.getPropertyValue('--paramount-blue'),
                border: cssVars.getPropertyValue('--paramount-border'),
            },
            'Max': {
                background: cssVars.getPropertyValue('--max-purple'),
                border: cssVars.getPropertyValue('--max-border'),
            },
            'Other': {
                background: cssVars.getPropertyValue('--other-color'),
                border: cssVars.getPropertyValue('--other-boarder'),
            },
        };

        const aggregatedData: { [key: string]: { dataUsage: number, duration: number } } = {};
        data.forEach((item: any) => {
            const nameConf = item.nameConf;
            const dataUsage = item.dataUsage;
            const duration = item.duration;

            let domainGroup = 'Other';
            for (const keyword in keywordMapping) {
                if (nameConf.includes(keyword)) {
                    domainGroup = keywordMapping[keyword];
                    break;
                }
            }
            if (aggregatedData[domainGroup]) {
                aggregatedData[domainGroup].dataUsage += dataUsage;
                aggregatedData[domainGroup].duration += duration;
            } else {
                aggregatedData[domainGroup] = { dataUsage, duration };
            }
        });

        const labels = Object.keys(aggregatedData);
        const dataUsage = Object.values(aggregatedData).map(item => item.dataUsage);
        const durations = Object.values(aggregatedData).map(item => item.duration);
        const backgroundColors = labels.map(label => colorMapping[label]?.background || 'rgba(0, 0, 0, 0.2)');
        const borderColors = labels.map(label => colorMapping[label]?.border || 'rgba(0, 0, 0, 1)');
        
        return {
            labels,
            datasets: [
                {
                    label: 'Data Usage',
                    data: dataUsage,
                    backgroundColor: backgroundColors,
                    borderColor: borderColors,
                    borderWidth: 1,
                },
            ],
            durations,
        };
    };

    const formatBytes = (bytes: number, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1000;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    const formatDuration = (duration: number) => {
        const days = Math.floor(duration / (60 * 60 * 24));
        const hours = Math.floor(duration / (60 * 60)) % 24;
        const minutes = Math.floor(duration / 60) % 60;
        const seconds = Math.floor(duration) % 60;
        return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    };

    if (loading) {
        return <div className="centered-container"><Loader /></div>;
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false, // Allows the chart to stretch to fit its container
        plugins: {
            legend: {
                position: 'top' as const,
            },
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        const label = context.label || '';
                        const value = context.raw;
                        const duration = chartData.durations[context.dataIndex];
                        return [
                            `${label}: ${formatBytes(value)}`,
                            `Duration: ${formatDuration(duration)}`,
                        ];
                    },
                },
            },
            datalabels: {
                color: '#fff',
                formatter: function (value: any, context: any) {
                    const total = context.chart.data.datasets[0].data.reduce((acc: number, val: number) => acc + val, 0);
                    const percentage = ((value / total) * 100).toFixed(2);
                    return `${formatBytes(value)} (${percentage}%)`;
                },
                anchor: 'end' as 'end',
                align: 'start' as 'start',
                offset: 10,
                backgroundColor: (context: any) => context.dataset.backgroundColor,
                borderRadius: 4,
                borderColor: 'white',
                borderWidth: 2,
                padding: 4,
            },
        },
    };

    return (
        <div className="chart-container-other grid-item" style={{ width: '100%', height: '100%' }}>
            {chartData ? (
                <Pie ref={chartRef} data={chartData} options={options} />
            ) : null }
        </div>
    );
};

export default PieChartComponent;